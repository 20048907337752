import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch, Ref, } from 'vue-property-decorator';
import { Form, FormItem } from '@/components/shared/CForm/index';
import { RoleSelector, StaffSelector } from '@cloudpivot-hermes/common-components';
// import StaffSelector from '@/components/staff-selector/index.vue';
// import RoleSelector from '@/components/role-selector';
import { AppAuthService } from '@/sevices';
import { AuthType, OperateType } from '@/typings/app-auth';
import * as Utils from '@/common/utils';
import Perm from '@/router/permission';
let AddAuthorityGroup = class AddAuthorityGroup extends Vue {
    constructor() {
        super(...arguments);
        this.visible = false;
        this.staffMode = 'autoScope';
        this.baseUserId = '';
        this.hasCodePerm = function () { };
        this.form = {
            name: '',
            authType: AuthType.Org,
            subjectDtoList: [],
        };
        this.AuthType = {
            Org: AuthType.Org,
            User: AuthType.User,
            Role: AuthType.Role,
        };
        this.OperateType = {
            Add: OperateType.Add,
            Edit: OperateType.Edit,
        };
        this.authDetail = {};
        this.modalTitle = '新建角色';
        this.nameRepeat = '';
        this.authTypes = [
            {
                label: '按组织',
                value: AuthType.Org,
            },
            {
                label: '按人员',
                value: AuthType.User,
            },
            {
                label: '按标签',
                value: AuthType.Role,
            },
        ];
        this.submiting = false;
        this.rules = {
            name: [{ required: true, message: '角色名称不能为空', trigger: 'blur' }],
            authType: [
                { required: true, message: '授权方式不能为空', trigger: 'change' },
            ],
            subjectDtoList: [
                { required: true, message: '范围不能为空', trigger: 'change' },
            ],
        };
    }
    get hasDistributePerm() {
        return this.hasCodePerm(this.$PermCode.appManage.distribute);
    }
    onFormChange() {
        Utils.trim(this.form);
    }
    created() {
        this.hasCodePerm = Perm.hasAppCodePerm(this.appCode);
    }
    onAuthTypesChange() {
        this.form.subjectDtoList = [];
    }
    async comfirm() {
        const isValid = this.cform.validate();
        if (!isValid)
            return;
        try {
            this.submiting = true;
            this.operateType === OperateType.Add
                ? await this.addAuthGroup()
                : await this.updateAuthGroup();
        }
        catch (error) {
            const errorInfo = JSON.parse(error);
            if (errorInfo.errCode === '11041002') {
                this.nameRepeat = '该角色名称已被占用';
            }
        }
        finally {
            this.submiting = false;
        }
    }
    getSubjectDtoList() {
        return this.form.subjectDtoList.map((item) => {
            if (this.form.authType === AuthType.Org && this.staffMode === 'autoScope') {
                return {
                    id: item.id,
                    name: item.name,
                    recursion: item.recursion,
                };
            }
            return {
                id: item.id,
                name: item.name,
            };
        });
    }
    // 添加角色信息
    async addAuthGroup() {
        const params = {
            name: this.form.name,
            authType: this.form.authType,
            appCode: this.form.appCode,
            subjectDtoList: this.getSubjectDtoList(),
        };
        const addAuthGroupRes = await AppAuthService.addAuthGroup(params);
        if (!addAuthGroupRes.success)
            return;
        const addAuthGroupData = addAuthGroupRes.data;
        this.$message.success('添加角色成功');
        this.$emit('showSetAuth', addAuthGroupData);
        this.$emit('reload');
        this.cancel();
    }
    // 编辑角色信息
    async updateAuthGroup() {
        const params = {
            name: this.form.name,
            authType: this.form.authType,
            appCode: this.form.appCode,
            subjectDtoList: this.getSubjectDtoList(),
            permGroupId: this.form.permGroupId,
        };
        const updateRoleRes = await AppAuthService.updateAuthGroup(params);
        if (!updateRoleRes.success)
            return;
        const updateAuthGroupData = updateRoleRes.data;
        console.log(updateAuthGroupData);
        this.$message.success('编辑角色成功');
        this.$emit('reload');
        this.cancel();
    }
    cancel() {
        this.$emit('input', false);
    }
    async getAuthDetail() {
        const res = await AppAuthService.getAuthDetail({
            permGroupId: this.permGroupId,
        });
        if (res.success) {
            this.authDetail = res.data;
        }
    }
    async onValueChange(v) {
        this.visible = v;
        if (!v) {
            await Utils.sleep(100);
            this.form = {};
            this.cform && this.cform.clearValidate();
            return;
        }
        if (this.operateType === OperateType.Edit) {
            await this.getAuthDetail();
            this.form = {
                appCode: this.appCode,
                ...this.form,
                ...this.authDetail,
            };
            this.modalTitle = '编辑角色';
        }
        else {
            this.form = {
                name: '',
                authType: AuthType.Org,
                subjectDtoList: [],
                appCode: this.appCode,
            };
            this.modalTitle = '新建角色';
        }
    }
};
__decorate([
    Prop()
], AddAuthorityGroup.prototype, "value", void 0);
__decorate([
    Prop({ default: OperateType.Add })
], AddAuthorityGroup.prototype, "operateType", void 0);
__decorate([
    Prop()
], AddAuthorityGroup.prototype, "appCode", void 0);
__decorate([
    Prop()
], AddAuthorityGroup.prototype, "permGroupId", void 0);
__decorate([
    Ref()
], AddAuthorityGroup.prototype, "cform", void 0);
__decorate([
    Watch('form', { immediate: true, deep: true })
], AddAuthorityGroup.prototype, "onFormChange", null);
__decorate([
    Watch('value')
], AddAuthorityGroup.prototype, "onValueChange", null);
AddAuthorityGroup = __decorate([
    Component({
        name: 'AddAuthorityGroup',
        components: {
            CForm: Form,
            CFormItem: FormItem,
            StaffSelector,
            RoleSelector,
        },
    })
], AddAuthorityGroup);
export default AddAuthorityGroup;
