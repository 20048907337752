export default new class ValidateForm {
    /**
     * 验证入口
     * @param value
     * @param rule
     */
    startValidate(value, rule) {
        if (rule.required) {
            const res = this.isEmpty(value);
            return { isError: res, message: rule.message };
        }
        ;
        if (rule.validator && typeof rule.validator === 'function') {
            const res = rule.validator(value);
            return { isError: !res, message: rule.message };
        }
        ;
        if (rule.type && this[rule.type] && typeof this[rule.type] === 'function') {
            const res = this[rule.type](value, rule);
            return { isError: !res, message: rule.message };
        }
        return { isError: false, message: '' };
    }
    /**
     * 是否是空值
     * @param value
     * @param rule
     */
    isEmpty(value) {
        let isEmpty = false;
        if (typeof value === 'string' || typeof value === 'undefined' || value === null)
            isEmpty = !value;
        if (Array.isArray(value))
            isEmpty = value.length <= 0;
        if (typeof value === 'number' || typeof value === 'boolean') {
            const numString = value.toString();
            isEmpty = !numString;
        }
        return isEmpty;
    }
    /**
     * 字符长度
     * @param value
     * @param rule
     */
    length(value, rule) {
        if (typeof rule.min === 'undefined' || typeof rule.max === 'undefined') {
            console.warn('Missing min or max');
            return true;
        }
        ;
        if (value.length > rule.min && value.length < rule.max) {
            return true;
        }
        else {
            return false;
        }
        ;
    }
    /**
     * 是否是纯数值
     * @param value
     * @param rule
     */
    isNumber(value) {
        if (/^[0-9]*$/.test(value)) {
            return true;
        }
        else {
            return false;
        }
        ;
    }
    /**
     * 是否邮箱地址
     * @param value
     * @param rule
     */
    isEmail(value) {
        // eslint-disable-next-line
        if (/^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/.test(value)) {
            return true;
        }
        else {
            return false;
        }
    }
    /**
     * 是否是手机号码
     * @param value
     * @param rule
     */
    isPhone(value) {
        if ((/^1[3456789]\d{9}$/.test(value))) {
            return true;
        }
        else {
            return false;
        }
    }
}();
