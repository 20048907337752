import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch, } from 'vue-property-decorator';
import validateForm from './validate';
// eslint-disable-next-line
import { BusEvents } from './Form.typings';
import Utils from './Utils';
import Bus from './bus';
// 允许鼠标移开立即验证的输入域
const BLUR_FIELDS = ['a-input', 'a-auto-complete', 'a-input-number', 'a-mentions'];
let FormItem = class FormItem extends Vue {
    constructor() {
        super(...arguments);
        this.isError = false;
        this.errmsg = '';
        this.initialValue = '';
    }
    // 表单字段是否内联
    get inline() {
        return this.form.inline;
    }
    // label的宽度
    get labelWidth() {
        return this.form.labelWidth;
    }
    // label的位置
    get labelPosition() {
        return this.form.labelPosition;
    }
    // 必填的位置
    get requiredPosition() {
        return this.form.requiredPosition;
    }
    // label是否是在表单域顶部
    get isPositionTop() {
        let res = false;
        if (this.labelPosition === 'top') {
            res = true;
        }
        return res;
    }
    // 表单域是否必填
    get required() {
        let res = false;
        if (!this.prop)
            return res;
        const rules = this.getRules();
        res = rules.some(rule => rule.required);
        return res;
    }
    // Form组件
    get form() {
        let parent = this.$parent;
        let parentName = parent.$options._componentTag;
        while (parentName !== 'c-form') {
            parent = parent.$parent;
            parentName = parent.$options._componentTag;
        }
        return parent;
    }
    // 当前字段的值
    get fieldValue() {
        const model = this.form.model;
        const prop = this.prop;
        if (!model || !prop) {
            return;
        }
        return Utils.getPropDataByProp('model', model, prop, true).value;
    }
    onPropChange(prop) {
        if (prop)
            return;
        this.clearValidate();
    }
    onErrorChange(value) {
        if (!this.prop)
            return;
        this.errmsg = value;
        this.isError = !!value;
    }
    /**
     * 获取当前字段的验证规则
     */
    getRules() {
        const orgFormRules = this.form.rules || [];
        const selfRules = this.rules || [];
        let formRules = [];
        if (!this.prop)
            return [];
        const propData = Utils.getPropDataByProp('rules', orgFormRules, this.prop || '');
        formRules = propData.value;
        // console.log('###########################');
        // console.log(this.prop + '：');
        // console.log([].concat(formRules, selfRules || []));
        return [].concat(formRules, selfRules || []);
    }
    /**
     * 获取当前字段带触发器的规则
     */
    getFilteredRule(trigger) {
        if (!this.prop)
            return [];
        const rules = this.getRules();
        return rules
            .filter(rule => {
            if ((rule.trigger && trigger === 'blur') ||
                (rule.trigger && trigger === 'change') ||
                trigger === 'click') {
                return true;
            }
            if (Array.isArray(rule.trigger)) {
                return rule.trigger.indexOf(trigger) > -1;
            }
            else {
                return rule.trigger === trigger;
            }
        })
            .map(rule => Object.assign({}, rule));
    }
    /**
     * 验证当前字段
     */
    validate(trigger = 'click') {
        if (!this.prop)
            return;
        const rules = this.getFilteredRule(trigger);
        const fieldValue = this.fieldValue;
        if (!rules || rules.length === 0)
            return false;
        if (!this.required) {
            if ((Array.isArray(fieldValue) && fieldValue.length === 0) ||
                (typeof fieldValue === 'string' && fieldValue === '') ||
                (typeof fieldValue === 'object' && fieldValue === null) ||
                (typeof fieldValue === 'undefined' && fieldValue === undefined)) {
                this.errmsg = '';
                this.isError = false;
                return false;
            }
        }
        ;
        for (const rule of rules) {
            const validateInfo = validateForm.startValidate(fieldValue, rule);
            this.isError = validateInfo.isError;
            if (this.isError) {
                this.errmsg = validateInfo.message;
                break;
            }
            else {
                this.errmsg = '';
            }
        }
        return this.isError;
    }
    /**
     * 清空验证规则
     */
    clearValidate() {
        this.isError = false;
        this.errmsg = '';
    }
    /**
     * 重置字段值
     */
    resetField() {
        this.isError = false;
        this.errmsg = '';
        const model = this.form.model;
        const value = this.fieldValue;
        const path = this.prop;
        const prop = Utils.getPropDataByProp('model', model, path);
        if (Array.isArray(value)) {
            prop.parentData[prop.key] = [].concat(this.initialValue);
        }
        else {
            prop.parentData[prop.key] = this.initialValue;
        }
        this.$nextTick(() => {
            this.clearValidate();
        });
    }
    /**
     * 当前字段绑定鼠标离开时的验证
     */
    // eslint-disable-next-line
    onFieldBlur(callback) {
        this.validate('blur');
    }
    /**
     * 当前字段绑定当前值改变时的验证
     */
    onFieldChange() {
        this.validate('change');
    }
    /**
     * 添加验证事件
     */
    addValidateEvents() {
        if (!this.prop)
            return;
        const rules = this.getRules();
        if (rules.length || this.required !== undefined) {
            const vNodes = this.$slots.default;
            const vNode = vNodes[0];
            if (!vNode)
                return;
            const componentInstance = vNode.componentInstance;
            const componentOptions = vNode.componentOptions;
            if (!componentInstance)
                return;
            if (!componentOptions)
                return;
            const tag = componentOptions.tag;
            let el = componentInstance.$el;
            if (tag === 'a-input-number' && el) {
                el = el.querySelector('input');
            }
            if (BLUR_FIELDS.includes(tag)) {
                const isBlurRule = rules.some(item => item.trigger === 'blur');
                if (!isBlurRule)
                    return;
                el.removeEventListener('blur', () => {
                    // console.log('removeBlur');
                });
                el.addEventListener('blur', this.onFieldBlur);
            }
            const isChangeRule = rules.some(item => item.trigger === 'change');
            if (!isChangeRule)
                return;
            this.$watch('fieldValue', (newValue, oldeValue) => {
                if (newValue === oldeValue)
                    return;
                this.onFieldChange();
            });
        }
    }
    /**
     * 移除验证事件
     */
    removeValidateEvents() {
        this.$off();
    }
    initFormItem() {
        if (this.prop) {
            Bus.$emit(BusEvents.AddFields, this);
            let initialValue = this.fieldValue;
            if (Array.isArray(initialValue)) {
                if (!initialValue.length)
                    initialValue = [];
                initialValue = [].concat(initialValue || []);
            }
            this.initialValue = initialValue;
            this.addValidateEvents();
        }
    }
    mounted() {
        this.initFormItem();
    }
    destroyed() {
        if (this.prop) {
            Bus.$emit(BusEvents.RemoveFields, this);
        }
    }
};
__decorate([
    Prop()
], FormItem.prototype, "label", void 0);
__decorate([
    Prop()
], FormItem.prototype, "prop", void 0);
__decorate([
    Prop()
], FormItem.prototype, "error", void 0);
__decorate([
    Prop()
], FormItem.prototype, "rules", void 0);
__decorate([
    Watch('prop')
], FormItem.prototype, "onPropChange", null);
__decorate([
    Watch('error')
], FormItem.prototype, "onErrorChange", null);
FormItem = __decorate([
    Component({
        name: 'cFormItem',
    })
], FormItem);
export default FormItem;
