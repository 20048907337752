export default class Utils {
    /**
     * 获取prop获取数据
     * @param data
     * @param prop
     */
    // eslint-disable-next-line
    static getPropDataByProp(dataName, data, prop, strict = false) {
        const propItems = prop.split('.');
        const length = propItems.length;
        const errorPath = [];
        let resData = data;
        let resProp = '';
        let parentData = data;
        // debugger
        for (const [index, propItem] of propItems.entries()) {
            errorPath.push(propItem);
            if (propItem in resData) {
                parentData = resData;
                resData = resData[propItem];
                resProp = propItem;
                if (index < length - 1) {
                    // 除了最后一个不需要验证是否是复合类型，其它都要验证
                    // 如果不是对象和数组类型，都抛出错误
                    if ((!(resData instanceof Object) &&
                        !(resData instanceof Array)) ||
                        resData === null) {
                        throw new Error(`[CForm Error][Form] property of ${errorPath.join('.')} is not object or array`);
                        // eslint-disable-next-line
                        break;
                    }
                }
            }
            else {
                // if (strict) console.warn(`[CForm Error][Form] property of ${errorPath.join('.')} does not exist ${dataName}`);
                parentData = {};
                resData = [];
                resProp = propItem;
                break;
            }
        }
        return {
            parentData: parentData,
            key: resProp,
            value: resData,
        };
    }
    /**
     * 深拷贝数据
     */
    static deepCopy(data) {
        const result = Array.isArray(data) ? [] : {};
        for (const key in data) {
            // eslint-disable-next-line
            if (!data.hasOwnProperty(key))
                continue;
            if (typeof data[key] === 'object' && data[key] !== null) {
                result[key] = this.deepCopy(data[key]);
            }
            else {
                result[key] = data[key];
            }
        }
        return result;
    }
}
